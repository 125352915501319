


import img1 from '../images/layouts/project-01.jpg'
import img2 from '../images/layouts/project-02.jpg'
import img3 from '../images/layouts/project-03.jpg'
import img4 from '../images/layouts/project-04.jpg'
import img5 from '../images/layouts/project-05.png'

const dataProject = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4, 
    },
    {
        id: 5,
        img: img5, 
    },
    {
        id: 6,
        img: img1,
    },
    {
        id: 7,
        img: img2,
    },
    {
        id: 8,
        img: img3,
    },
    {
        id: 9,
        img: img4,
    },


    

]

export default dataProject;