import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data: staticData } = props;

  const [projectData, setProjectData] = useState([]);
  const [dataBlock] = useState({
    subheading: 'Zion City',
    heading: 'Gallery',
  });

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint for your project data
        const response = await fetch('https://zapi.zxcommunity.in/getimages');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data)
        setProjectData(data.data);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, []); // The empty dependency array ensures that the effect runs only once, similar to componentDidMount

  return (
    <section className="project">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
            </div>

            <Swiper
              className="project-swiper"
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              loop={true}
              modules={[Navigation, Pagination]}
              navigation
              pagination={{
                clickable: true,
              }}
            >
              {projectData.map((project) => (
                <SwiperSlide key={project.id}>
                  <div className="swiper-slide">
                    <div className="project-box ">
                      <div className="image">
                        <img src={project.img} alt="Project" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
