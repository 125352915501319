import React , {useState} from 'react';

import Button from '../button/Button';


import img from '../../assets/images/layouts/about-06.png'


function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'Zion City',

            desc1: 'Whole World on a single island 💗',
            desc2: 'Zion Roleplay is a community that generally consists of individuals over the age of 18, however, this does not mean you cannot play within our community. We respect and recognize all ages of people that are in our community.',
           
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src={img} alt="Cyfonii" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    {/* <Button title='More About Us' link='/about' /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About2;