import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';



function Page404(props) {
    return (
    <PageTitle title='No Page Found !!' /> 
        
    );
}

export default Page404;